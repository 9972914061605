$accentDark: #25408f;
$accentLight: #952376;
$accentBeige: #f1e7e1;
$accentPink: #eaa69b;
$accentOrange: #e46936;
$accentYellow: #ed9a00;
$white: $accentBeige;
$background: $accentBeige;
$box-shadow-accent: 6px 6px 0px $accentYellow;
$box-shadow-gray: 0px 0px 7px 2px #ececec;
$menuItem-shadow: true !default;

.rc-tabs-top .rc-tabs-bar {
  border-bottom: 2px solid $accentLight !important;
  padding: 1rem 0;
}

.rc-tabs-ink-bar {
  height: initial !important;
  width: 100% !important;
}

.rc-tabs-tab-active {
  background-color: $accentPink !important;
  border-radius: 50px;
  span {
    color: $accentDark !important;
  }
}

%cart {
  .cart {
    &-content {
      background: $accentBeige !important;
    }
  }
}

%fieldsContainer {
  background-color: $accentBeige !important;
}

%loyaltyHeader {
  .loyaltyHeader {
    &-description {
      max-width: 20rem;
      &-text {
        text-align: left;
      }
    }
  }
}
// This is a flaky css selector and should be fixed at some point
// However the component lib doesnt extend outside of parent class .location-info-card
%locationInfo {
  & > div:first-child {
    line-height: 1.75;
  }
  & > div:nth-child(2) {
    padding-top: 0rem;
    & > div {
      line-height: 1.75;
    }
  }
}

%location {
  background-color: $accentBeige;
  .location-container--header {
    background-color: $accentBeige;
  }
}

%locationCard {
  background: white;
  box-shadow: none;

  .location-card:first-child {
    border-radius: 0;
    border-bottom: 2px solid $accentDark;
  }

  button {
    padding: 0.5rem;
  }
}

%itemDetails-tabs {
  .itemDetails-tabs {
    &-nav-container {
      .nav {
        background-color: $accentBeige !important;

        .nav-item {
          .item-button {
            padding: 0.5rem 1rem;
            background-color: $accentBeige !important;
            span {
              color: $accentDark !important;
            }
          }
          &[data-active="1"] {
            .item-button {
              background-color: $accentPink !important;
              border-radius: 1rem;
            }
          }
        }
      }
    }
  }
}

%quantitySelector {
  padding-bottom: 0 !important;
}

%menuGroup {
  .group {
    &-info {
      border-bottom: 2px solid $accentLight;
      margin: 3rem 2rem 1rem 2rem;
      padding: 0 0 1rem 0;
    }
  }
}

%modifierItem {
  .item {
    &-qty--number {
      background-color: $accentOrange;
    }
    &-qty--btn {
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
    }
  }
}

%menuItem {
  box-shadow: $box-shadow-gray !important;
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: $box-shadow-accent !important;
  }
  .menuItem {
    &-dietary {
      position: relative !important;
      bottom: auto !important;
      right: 0 !important;
      margin-left: 0.5rem !important;
    }
  }
}

%packingItem {
  .packingItem {
    &-radio {
      label {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

%order {
  .order {
    &-header {
      border-right: none;
      border-left: none;
      border-top: none;
    }
  }
}

%upsellItem {
  .upsellItem {
    &-cart-btn {
      flex-grow: initial;
    }
  }
}

%giftCard {
  .giftCard {
    &--logoContainer {
      max-width: 20%;
      margin: 0 auto;
    }
  }
}

%tipsContainer {
  .tipsContainer {
    &-wrapper {
      &--customAmountSelected {
        color: white !important;
      }
    }
  }
}

//increase close button size to fill up the blank area
%itemDetails {
  .itemDetails-back {
    img {
      height: 1rem !important;
    }
  }
}
